<template>
    <div>
        <el-tag style="margin: 0 5px 5px 0" closable @close="handleCloseTag(index)" type="primary" size="small" v-for="(item,index) in tagVoList||[]">{{item.tagName}} </el-tag>
        <div>
            <el-tag @click="addNewTag" style="margin: 0 5px 5px 0;cursor: pointer;font-weight: 600" effect="dark" type="primary" size="small">+ 新标签</el-tag>
        </div>
        <el-dialog
            title="选择标签"
            :visible.sync="tagDialogVisible"
            width="80%" append-to-body>
            <el-scrollbar style="height: 400px" v-loading="tagDialogLoading">
                <div v-for="item in tagList" style="border-bottom: 1px solid #DCDFE6;padding: 15px 0">
                    <h3 style="padding:0;margin:0 0 15px 0">{{item.name}}</h3>
                    <el-tag @click="selectTag(item1)" style="margin: 0 10px 10px 0;cursor: pointer" :type="item1.selState?'primary':'info'" size="small" v-for="(item1,index) in item.list||[]">{{item1.name}} </el-tag>
                </div>
            </el-scrollbar>

            <span slot="footer" class="dialog-footer">
                <el-button @click="tagDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitTag">确 定</el-button>
              </span>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        data(){
            return {
                tagDialogVisible:false,
                tagDialogLoading:false,
                tagList:[],
                selTagList:[],
            }
        },
        props:['tagVoList'],
        mounted() {

        },
        methods:{
            initTabs(){
                this.tagDialogLoading = true
                this.$apiGet('lechun-cms/tag/getTagList', {pageNum: 1,pageSize: 10000}).then(res => {
                    let tagObj = {}
                    res.list.map(item=>{
                        item.selState = false;
                        item.tagName = item.name//与获取详情接口参数对应，添加参数名
                        item.tagId = item.wxContactTagId;//与获取详情接口参数对应，添加参数名
                        (this.tagVoList||[]).map(item1=>{
                            if(item.wxContactTagId==item1.tagId){
                                item.selState = true
                            }
                        })
                        if(tagObj.hasOwnProperty(item.tagGroupName)){
                            tagObj[item.tagGroupName].push(item)
                        }else{
                            tagObj[item.tagGroupName] = [item]
                        }
                    })
                    let tagList = []
                    for(let key in tagObj){
                        tagList.push({name:key,list:tagObj[key]})
                    }
                    this.tagDialogLoading = false
                    this.tagList = tagList
                    this.selTagList = this.tagVoList||[]
                })
            },
            handleCloseTag(index){
                this.$emit('handleCloseTag',index)
            },
            addNewTag(){
                this.tagDialogVisible = true
                this.initTabs()

            },
            selectTag(item){
                if(!item.selState){
                    item.selState = true
                    this.selTagList.push(item)
                }
            },
            submitTag(){
                this.tagDialogVisible = false
                this.$emit('selectTags',{value:this.selTagList})
            }
        }
    }
</script>
<style scoped>

</style>