<template>
    <defaultSec :title="'< 返回'" :returnState="true">
        <div>
            <el-form :model="form" label-width="120px">
                <el-form-item label="任务名称" prop="taskName" :rules="[{required:true,message:'名称不能为空'}]">
                    <el-input v-model="form.taskName" placeholder="请输入任务名称"></el-input>
                </el-form-item>
                <el-form-item label="文案" prop="momentText">
                    <el-input v-model="form.momentText" placeholder="请输入文案"></el-input>
                </el-form-item>
                <el-form-item label="内容类型" prop="mediaType" :required="true">
                    <el-select v-model="form.mediaType" value="text" placeholder="请选择内容类型">
                        <el-option v-for="item in mediaTypeList"
                                   :key="item.value"
                                   :label="item.name"
                                   :value="item.value"
                        ></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="素材" v-show="form.mediaType != 'text'" :required="true">
                    <materialSel :materialVoList="form.mediaList" @selectMaterial="selectMaterial" @handleCloseMaterial="handleCloseMaterial"></materialSel>
                </el-form-item>
                <el-form-item label="客服">
                    <customerServiceList :multipleType="0" :selectUsers="form.senderUserList" @selectUsers="selectUsers"></customerServiceList>
                </el-form-item>
                <el-form-item label="客户标签">
                    <customerTabs :tagVoList="form.tagList" @selectTags="selectTags" @handleCloseTag="handleCloseTag"></customerTabs>
                </el-form-item>
                <el-form-item>
                      <el-button type="danger" @click="save()" size="small" class="header-btn">保存</el-button>
                </el-form-item>
            </el-form>
        </div>
    </defaultSec>
</template>

<script>
import defaultSec from '@c/defaultSection'
import tablePagination from '@c/tablePagination'
import customerTabs from '@c/scrm/publicComponent/customerTabs'
import customerServiceList from "@c/scrm/publicComponent/customerServiceList";
import materialSel from "@c/scrm/publicComponent/materialSel";

export default {
    data() {
        return {
            materialList: [],
            qwList: [],
            tagList: [],
            tagVoList:[],
            mediaTypeList: [{value:'image',name:'图片'},{value:'video',name:'视频'},{value:'link',name:'链接'},{value:'text',name:'纯文字'}],
            form: {
                id: this.$route.params.id || 0,
                taskName: '',
                momentText: '',
                mediaType: '',
                mediaId: '',
                senderUser: '',
                customerTag: '',
                tagList:[],
                senderUserList:[],
                mediaList:[],
            }
        }
    },
    components: {
        defaultSec, tablePagination,customerTabs,customerServiceList,materialSel
    },
    computed: {

    },
    mounted(){
        this.getMoment();
    },
    methods: {
        getMoment(){
            this.$apiGet('lechun-cms/moment/getMoment',{'id':this.form.id}).then(res => {
                this.form = {
                    ...res,
                    senderUserList:res.senderUserList.map(item=>{
                        return {qyWeixinUserId:item.qyWeixinUserid,qyWeiXinUserName:item.distributorName}
                    }),
                    tagList:res.tagList.map(item=>{
                        item.tagName = item.name
                        item.tagId = item.wxContactTagId
                        return item
                    }),
                    mediaList:res.mediaList.map(item=>{
                        item.materailId = item.id
                        return item
                    })
                }

            })
        },
        selectTags(val){
            console.log(val)
            this.form.tagList = val.value
        },
        handleCloseTag(index){
            this.form.tagList.splice(index,1)
        },
        selectUsers(val){
            this.form.senderUserList = val.value
        },
        selectMaterial(val){
            this.form.mediaList = val.value
        },
        handleCloseMaterial(index){
            this.form.mediaList.splice(index,1)
        },
        save(){
            if (this.form.taskName == '') {
                this.$message({
                    showClose: true,
                    message: '任务名称必填',
                    type: 'error'
                });
                return;
            }
            let form = {
              ...this.form,
              mediaId: this.form.mediaList.map(item=>{
                return item.materailId
              }),
              senderUser: this.form.senderUserList.map(item=>{
                return item.qyWeixinUserId
              }),
              customerTag: this.form.tagList.map(item=>{
                return item.tagId
              }),

            }
            console.log('this.form',form);
            this.$apiPost('lechun-cms/moment/addMoment', form).then(res => {
                console.log(res)
                this.$message({
                    message: '保存成功',
                    type: 'success'
                });
                this.$router.push({path: '/momentList', name: 'momentList'})
            })
        }
    }
}
</script>
