<template>
    <div>
        <el-tag style="margin: 0 5px 5px 0" closable @close="handleCloseMaterial(index)" type="info" size="small" v-for="(item,index) in materialVoList||[]">{{item.materialName}}</el-tag>
        <div>
            <el-tag @click="addMaterial" style="margin: 0 5px 5px 0;cursor: pointer;font-weight: 600" effect="dark" type="primary" size="small">+ 添加素材</el-tag>
        </div>
        <el-dialog
            title="选择素材欢迎语"
            :visible.sync="materialDialogVisible"
            width="80%" append-to-body>
            <el-scrollbar style="height: 400px" >
                <el-form :model="formParam" :inline="true" size="mini" class="demo-form-inline">
                    <el-form-item label="类型">
                        <el-select size="mini" v-model="formParam.materialType" placeholder="请选择">
                            <el-option label="文本" value="text"></el-option>
                            <el-option label="(临时)图片/视频/语音/文件" value="media"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="素材编码">
                        <el-input size="mini" type="text" v-model="formParam.materialCode" placeholder="请输入素材编码"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="danger" size="mini" plain @click="initMaterial">查询</el-button>
                    </el-form-item>
                </el-form>
                <tablePagination :total="total" :pageSize="formParam.pageSize" @handlePage="handlePage">
                    <el-table height="320" class="material-table" :data="materialList" border size="mini"
                              v-loading="materialDialogLoading"
                              @select="handleSelectionChange">
                        <el-table-column
                            type="selection"
                            width="55">
                        </el-table-column>
                        <el-table-column type="index" label="序号"></el-table-column>
                        <el-table-column prop="materialCode" label="素材编码"></el-table-column>
                        <el-table-column label="预览">
                            <template slot-scope="scope">
                                <div v-if="scope.row.materialType=='text'" class="copy-text" v-html="scope.row.pushText"></div>
                                <div v-if="scope.row.mediaType=='image'" style="max-height: 300px">
                                    <el-image  :src="scope.row.ossUrl||scope.row.mediaUrl" :preview-src-list="[scope.row.ossUrl||scope.row.mediaUr]"></el-image>

                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </tablePagination>

            </el-scrollbar>

            <span slot="footer" class="dialog-footer">
                <span style="font-size: 14px;margin-right: 20px">已选择<b style="color: #e50011">{{this.selMaterialList.length}}</b>个素材</span>
                <el-button size="mini" @click="materialDialogVisible = false">取 消</el-button>
                <el-button size="mini" type="primary" @click="submitMaterial">确 定</el-button>
              </span>
        </el-dialog>
    </div>
</template>
<script>
import tablePagination from '@c/tablePagination'

    export default {
        data(){
            return {
                materialDialogVisible:false,
                materialDialogLoading:false,
                materialList:[],
                selMaterialList:[],
                formParam: {
                    pageSize: 10,
                    currentPage: 1,
                    materialCode: '',
                    materialName: '',
                    materialType: this.materialType,
                    groupId: '',
                    status: 1,
                    firstSnedTime: null,
                    date: null,
                },
                total:0,
            }
        },
        components: {
            tablePagination
        },
        props:{
            materialVoList:{
                type:Array,
                default:[]
            },
            materialType:{
                type:String,
                default: 'text'
            }
        },
        mounted() {

        },
        methods:{
            //素材列表
            initMaterial(){
                this.materialDialogLoading = true
                this.$apiGet('lechun-cms/scrmMaterial/materialPageList', this.formParam).then(res => {
                    this.materialDialogLoading = false
                    this.materialList = res.list
                    this.total = res.total
                })
            },
            //素材
            addMaterial(){
                this.selMaterialList = []
                this.materialDialogVisible = true
                this.materialDialogLoading = true
                this.initMaterial()
            },
            handlePage(page){
                this.formParam.currentPage = page;
                this.initMaterial()
            },
            handleSelectionChange(val){
                console.log(val)
                this.selMaterialList = val

            },
            handleCloseMaterial(index){
                this.$emit('handleCloseMaterial',index)
            },
            submitMaterial(){
                this.materialDialogVisible = false
                this.$emit('selectMaterial',{value:[...this.materialVoList,...this.selMaterialList]})
            },
        }
    }
</script>
<style scoped>

</style>